<template>
    <!-- 正在拉取信息不展示 -->
    <ContentField v-if="!$store.state.user.pulling_info">
        <div class="row justify-content-md-center">
            <div class="col-3">
                <!-- 提交触发 阻止默认行为 -->
                <form @submit.prevent="login"> 
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">提交</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '@/components/ContentField.vue';
import router from '@/router';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'RecordIndexView',
    components: {
        ContentField,
    },
    setup() {
        // 取出全局变量
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');

        // // 判断是否需要展示登录页面
        // let show_content = ref(false);

        // 进入到登录页面时判断本地是否存放token （包括重定向）
        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updateToken", jwt_token);
            store.dispatch("getinfo", {
                success() {
                    // 成功的话跳转
                    router.push({ name: 'home' });
                    store.commit("updatePullingInfo", true);
                },
                error() {
                    // token过期的话 显示登录页面
                    store.commit("updatePullingInfo", false);
                }
            });
        } else {
            store.commit("updatePullingInfo", false);
        }

        const login = () => {
            error_message.value = "";
            // 调用函数
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                // 登入成功自动跳转
                success() {
                    // 登入成功的话获取用户信息
                    store.dispatch("getinfo", {
                        success() {
                            // 成功的话跳转
                            router.push({ name: 'home' });
                        }
                    });
                },
                error() {
                    error_message.value = "用户名或密码错误";
                }
            })
        }

        return {
            username,
            password,
            error_message,
            login,
        }
    }
}
</script>

<style scoped>
button {
    width: 100%;
}

div.error-message {
    color: red;
}
</style>